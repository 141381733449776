<template>
  <div>
    <b-row
      class="content-header"
    >
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="content-header-title float-left pr-1 mb-0">
              Course title
            </h2>
            <div class="breadcrumb-wrapper">
              <b-breadcrumb>
                <b-breadcrumb-item
                  :to="{ name: 'dashboard-mechtech' }"
                >
                  <feather-icon
                    icon="HomeIcon"
                    size="16"
                    class="align-text-top"
                  />
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :to="{ name: 'course-detail', params: { slug: course.slug } }"
                >
                  Course title
                </b-breadcrumb-item>
                <b-breadcrumb-item
                  :active="true"
                >
                  Subject title
                </b-breadcrumb-item>
              </b-breadcrumb>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="pb-2 h-75">
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="subject.subject_url"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2">
        <b-button variant="primary" block>
          <span>
            <feather-icon
              size="16"
              icon="ChevronLeftIcon"
            />
            Previous
          </span>
        </b-button>
      </b-col>
      <b-col md="8" />
      <b-col md="2">
        <b-button variant="primary" block>
          Next
          <span>
            <feather-icon
              size="16"
              icon="ChevronRightIcon"
            />
          </span>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <b-card class="mb-4" title="Subject Descriptions">
          <b-card-body class="p-0">
            <b-row>
              <b-col>
                <p>
                  {{ subject.description }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="d-flex justify-content-end">
                <b-button variant="outline-success">
                  Complete
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col />
    </b-row>
  </div>
</template>

<script>

export default {
  name: "Learning",
  components: {
  },
  data() {
    return {
      course: {
        title: "Course 1",
        slug: "course-1",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel fringilla mauris, eu interdum ligula. Fusce feugiat massa nec justo pulvinar, non tincidunt nisi viverra. In hac habitasse platea dictumst",
        price_regular: 500000,
        price_actual: 250000,
        application_name: "solidworks",
        thumbnail: "https://mechtechidn.com/wp-content/uploads/2021/09/Screenshot-2021-09-24-at-13.03.48-150x150.png"
      },
      subject: {
        course_id: 1,
        module_id: 10,
        title: "course 1 module 2 subject 2",
        subject_url: "https://www.youtube.com/embed/VgBq4gV4Whc?si=TJyd46mcUQDdnVkr",
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel fringilla mauris, eu interdum ligula. Fusce feugiat massa nec justo pulvinar, non tincidunt nisi viverra. In hac habitasse platea dictumst"
      }
    };
  },
};
</script>

<style scoped>

</style>
